<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['fixed'],
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('cart', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
    }),
  },
  methods: {
    toHome() {
      if (this.isDinein) {
        if (this.$cookies.get('table-no')) {
          return `/dinein/${this.$cookies.get('table-no')}`;
        } else {
          return `/scan`;
        }
      } else {
        return `/menu`;
      }
    },
    getWhatsAppUrl() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 'https://api.whatsapp.com/send?phone='
        : 'https://web.whatsapp.com/send?phone=';
    },
  },
};
</script>
<template>
  <v-bottom-navigation
    background-color="#e1cfc7"
    class="bottom-nav-home"
    :fixed="fixed"
    height="48px"
    hide-on-scroll
  >
    <v-btn class="text-decoration-none h-auto" to="/">
      <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Home</span>
    </v-btn>

    <v-btn class="text-decoration-none h-auto" to="/menubook">
      <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">E-Menu</span>
    </v-btn>

    <v-btn class="text-decoration-none h-auto">
      <v-img
        position="center"
        src="@/assets/dayonedayone/images/install-icon.webp"
        style="top:-35px"
        width="60px"
      ></v-img>
      <span class="position-relative text-caption font-weight-bold" style="color:#f79520; bottom:-42px">Install</span>
    </v-btn>

    <v-btn class="text-decoration-none h-auto" to="/dayonedayone/loyalty/voucher">
      <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Vouchers</span>
    </v-btn>

    <v-btn class="text-decoration-none h-auto" to="/dayonedayone/profile">
      <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Profile</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<style scoped>
.bottom-nav-home {
  box-shadow: none;
  padding: 0 5%;
}

.v-btn /deep/ i.rounded-circle {
  background-color: var(--v-primary) !important;
}

.v-bottom-navigation .v-btn {
  min-width: 70px !important;
}

@media screen and (min-width: 1024px) {
  .bottom-nav-home {
    padding: 0 20px !important;
  }
}
</style>
