<template>
  <section class="whatsnewsec">
    <v-main>
      <v-container>
        <v-card 
          flat
        >
          <v-card-title class="font-weight-6 primary--text">What's New</v-card-title>
          <v-card-text v-html="news"></v-card-text>
        </v-card>
        <BottomNav :fixed="true"></BottomNav>
      </v-container>
    </v-main>
  </section>
</template>

<script>
import BottomNav from '../components/partials/BottomNav';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    BottomNav,
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
      news: (state) => (state.news || 'No latest news update available now'),
    }),
  },
  methods: {
    ...mapActions('merchant', ['getMerchantNews']),
  },
  async mounted() {
    this.$gtag.event('view_whatsnews', {
      'event_category': 'navigation',
      'event_label': 'View What\'s News',
    });

    await this.getMerchantNews(this.profile.merchant_id);
  },
};
</script>

<style></style>
