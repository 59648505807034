import loyaltyApi from '../../api/loyalty';
const state = {
  tiers: [],
  reward: null,
  rewards: [],
  vouchers: [],
  pointBalance: 0,
};

const mutations = {
  SET_TIERS(state, tiers) {
    state.tiers = tiers;
  },
  SET_REWARD(state, reward) {
    state.reward = reward;
  },
  SET_REWARDS(state, rewards) {
    state.rewards = rewards;
  },
  SET_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers;
  },
  SET_POINT_BALANCE(state, pointBalance) {
    state.pointBalance = pointBalance;
  },
};

const actions = {
  async create(context, data) {
    const res = await loyaltyApi.create(context.rootState.auth.token, data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }
    return res.data;
  },
  async getLoyaltyTiers(context, query) {
    let tiers = (await loyaltyApi.findAllTiers(query))?.data;

    context.commit('SET_TIERS', tiers);
    return tiers;
  },
  async redeemLoyaltyReward(context, { id, data }) {
    const res = await loyaltyApi.createRedeemReward(context.rootState.auth.token, id, data);
    if (res?.status != 200) {
      throw Error(res.data);
    }
    return res.data;
  },
  async getLoyaltyReward(context, { id, query }) {
    const res = await loyaltyApi.findReward(id, query);
    if (res?.status != 200) {
      throw Error(res.data.message);
    }
    context.commit('SET_REWARD', res?.data);
    return res?.data;
  },
  async getLoyaltyRewards(context, query) {
    let rewards = (await loyaltyApi.findAllRewards(query))?.data;

    context.commit('SET_REWARDS', rewards);
    return rewards;
  },
  async getLoyaltyVouchers(context, query) {
    let vouchers = (await loyaltyApi.findAllVouchers(context.rootState.auth.token, query))?.data;

    context.commit('SET_VOUCHERS', vouchers);
    return vouchers;
  },
  async getLoyaltyPointBalance(context, query) {
    let pointBalance = (await loyaltyApi.findPointBalance(context.rootState.auth.token, query))?.data;

    context.commit('SET_POINT_BALANCE', pointBalance);
    return pointBalance;
  },
};

const getters = {
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
