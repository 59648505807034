<script>
import BottomNav from '@/components/partials/dayonedayone/BottomNav'
import NotifyUpdateModal from '@/components/DialogModals/NotifyUpdateModal';
import CentralLoader from '@/components/Loaders/CentralLoader';
import DeliveryProviderBottomSheet from '@/components/BottomSheets/DeliveryProviders';
import BranchModal from '@/components/Modals/Branches';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: ['installable'],
  components: {
    BottomNav,
    NotifyUpdateModal,
    CentralLoader,
    DeliveryProviderBottomSheet,
    BranchModal,
  },
  metaInfo() {
    return {
      title: `${this.profile.restaurant_name}`,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.merchantLogo },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      version: null,
      isLoading: false,
      hasUpdate: false,
      title: 'Day One Day One',
      serviceRoutes: {
        menubook: 'menubook',
        delivery: 'menu',
        pickup: 'menu',
        dinein: 'scan',
        reservation: 'reserve',
      },
      showDeliveryProvider: false,
      showBranch: false,
      alertMessage: {
        'invalid-voucher': 'Voucher is invalid or expired!',
      }
    };
  },
  computed: {
    ...mapState('loyalty', {
      rewards: (state) => state.rewards,
      pointBalance: (state) => state.pointBalance,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
      reviews: (state) => state.reviews,
      storeClosed: (state) => state.storeClosed,
      ondemand: (state) => state.ondemand,
      acceptPreOrder: (state) => state.preOrder,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('client', {
      name: 'getName',
    }),
    ...mapGetters('merchant', {
      rating: ['getRating'],
      isTodayHoliday: ['isTodayHoliday'],
      hasTableBooking: ['hasTableBooking'],
      hasBranch: ['hasBranch'],
    }),
    appBarBackground() {
      return this.profile.photo_landing_bg ? `${this.cdnUrl}/${this.profile.photo_landing_bg}` : '';
    },
    landingBackground: function() {
      return this.profile.photo_landing_bg ? {
        backgroundImage: `url(${this.cdnUrl}/${this.profile.photo_landing_bg})`
      } : null;
    },
    merchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    hasLinks: function () {
      return Object.values(this.profile?.links || {})?.some(link => !!link);
    }
  },
  methods: {
    ...mapActions('customer', ['claimVoucher']),
    ...mapActions('loyalty', ['getLoyaltyRewards', 'getLoyaltyPointBalance']),
    ...mapActions('merchant', [
      'getMerchantByHostname',
    ]),
    ...mapMutations('cart', ['SET_SERVICE']),
    hasService(value) {
      return this.profile?.service.find(s => s.value === value);
    },
    selectService(label, value) {
      this.$gtag.event('select-service', {
        'event_category': 'navigation',
        'event_label': label,
        'event_value': value,
      });

      if (value !== 'menubook') {
        this.SET_SERVICE(value);
      }

      if (value === 'delivery' && this.hasLinks) {
        this.showDeliveryProvider = true;
      } else {
        this.$router.push({name: this.serviceRoutes[value]}).catch(() => {});
      }
    },
    scan() {
      this.$gtag.event('scan', {
        'event_category': 'interaction',
        'event_label': 'Scan',
      });
      this.$router.push({name: 'scan'}).catch(() => {});
    },
    install() {
      this.$emit('install', 'home');
    },
    async autoClaimVouchers() {
      const unclaimedVouchers = JSON.parse(localStorage.vouchers || '[]');
      try {
        this.isLoading = true;

        await Promise.all(unclaimedVouchers.map(v => this.claimVoucher({
          voucherId: v.id,
          merchantId: this.profile.merchant_id,
        })));
      } finally {
        localStorage.removeItem('vouchers');
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);

    if (this.$route.query?.alert) {
      alert(this.alertMessage[this.$route.query?.alert]);
    }

    if (localStorage.token) {
      this.autoClaimVouchers();
    }

    if (this.isLoggedIn) {
      this.getLoyaltyPointBalance({
        merchantId: this.profile.merchant_id,
      });
    }
  },
  async created() {
    try {
      this.isLoading = true;

      if (!this.profile) {
        // Get merchant profile
        await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);
      }

      // Load Loyalty Rewards
      this.getLoyaltyRewards({
        merchantId: this.profile.merchant_id,
      })
      
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  },
};
</script>
<template>
  <v-card class="home-sec" elevation="0">
    <v-img
      class="home-banner"
      height="40dvh"
      position="top center"
      :src="appBarBackground"
    ></v-img>
    <CentralLoader v-if="isLoading || !profile" color="white"></CentralLoader>
    <div
      v-if="!isLoggedIn"
    >
      <v-card class="container-join-member" elevation="0">
        <div class="row m-0 p-0">
          <div class="col-8">
            <h5 class="font-weight-bold">Welcome<br/>DayOne DayOne</h5>
            <div class="pt-1" style="font-size:0.65rem; letter-spacing:0px !important;">
              Sign up as member, activate to enjoy VIP privileges
            </div>
          </div>
          <div class="col-4">
            <v-img
              contain
              src="@/assets/dayonedayone/images/signup-btn.webp"
              class="position-absolute"
              height="100px"
              position="center"
              style="z-index:7; top:-35px; left:0px;"
            ></v-img>
            <v-btn
              class="position-absolute text-capitalize font-weight-bold rounded-lg p-2"
              style="z-index:7; top:61px; font-size:10px; letter-spacing:0px !important; background-color: #e1cfc7;"
              depressed
              @click="$router.push({ name: 'dayonedayone-signup' }).catch(() => {});"
            >
              <span>Sign-up</span> / <span class="tertiary--text">Log In</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else>
      <v-card class="container-loyalty-profile" elevation="0">
        <div class="row m-0 p-0">
          <div class="col-9">
            <h5 class="font-weight-bold mb-0">Hi<br/>{{ name }}, <span class="tertiary--text">{{ pointBalance }}</span> <span class="text-body-2 font-weight-6">points</span></h5>
            <div class="text-caption font-weight-5">
              DayOne DayOne Member
            </div>
          </div>
          <div class="col-3 pl-0">
            <v-img
              contain
              src="@/assets/dayonedayone/images/container-home-profile.webp"
              class="position-absolute"
              height="60px"
              position="center"
              style="z-index:7; top:15px; left:0px;"
            ></v-img>
          </div>
        </div>
      </v-card>
    </div>
    <div class="container-menu">
      <div class="row m-0 p-0">
        <div class="col-12 p-0">
          <div class="sub-container-menu">
            <div class="row m-0" style="height:8dvh;">
              <div class="col-2 p-2">
                <v-img
                  contain
                  height="7dvh"
                  position="center right"
                  src="@/assets/dayonedayone/images/sub-menu-store.webp"
                ></v-img>
              </div>
              <div class="col-10 d-flex flex-column justify-space-between p-2 pl-3">
                <h6 class="font-weight-bold mb-0" style="font-size:1.0rem;">{{ profile.restaurant_name }}</h6>
                <div style="font-size:0.5rem;">Click for DayOne DayOne Stores</div>
              </div>
            </div>
            <div class="row align-end m-0 mt-3 pb-3" style="height:12dvh;">
              <div class="col-3 p-2">
                <v-img
                  contain
                  class="position-relative"
                  height="6dvh"
                  position="top center"
                  src="@/assets/dayonedayone/images/signatures.webp"
                  style="top:-5px;"
                >
                </v-img>
                <div class="sub-menu-label">Signatures</div>
              </div>
              <div class="cursor-pointer col-3 p-2" @click="selectService('Menubook', 'menubook')">
                <v-img
                  contain
                  height="6dvh"
                  position="center center"
                  src="@/assets/dayonedayone/images/emenu.webp"
                >
                </v-img>
                <div class="sub-menu-label">E-Menu</div>
              </div>
              <div class="cursor-pointer col-3 p-2" @click="selectService('Delivery', 'delivery')">
                <v-img
                  contain
                  height="6dvh"
                  position="center center"
                  src="@/assets/dayonedayone/images/delivery.webp"
                >
                </v-img>
                <div class="sub-menu-label">Delivery</div>
              </div>
              <div class="cursor-pointer col-3 p-2" @click="selectService('Pickup', 'pickup')">
                <v-img
                  contain
                  height="6dvh"
                  position="bottom center"
                  src="@/assets/dayonedayone/images/pickup.webp"
                >
                </v-img>
                <div class="sub-menu-label">Pick-up</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-img
        contain
        class="home-menu-footer-img position-absolute"
        width="145"
        position="bottom right"
        src="@/assets/dayonedayone/images/home-menu-footer.webp"
      ></v-img>
    </div>
    <v-divider class="footer-divider m-0 p-0"></v-divider>
    <BottomNav :fixed="true"/>
    <notify-update-modal :version="version" v-if="version && hasUpdate"></notify-update-modal>
    <delivery-provider-bottom-sheet :visible="showDeliveryProvider" v-on:close-delivery-provider="showDeliveryProvider = false"></delivery-provider-bottom-sheet>
    <branch-modal :visible="showBranch" v-on:close-branch="showBranch = false"></branch-modal>
  </v-card>
</template>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.home-sec {
  height: 100dvh;
  height: -webkit-fill-available;
  padding-bottom: 45px;
}

.container-join-member {
  width: 90%;
  height: 12.5dvh;
  position: absolute;
  z-index: 5;
  top: calc(40dvh - 65px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(247, 239, 232, 0.8);
}

.container-loyalty-profile {
  width: 90%;
  height: 12.5dvh;
  position: absolute;
  z-index: 5;
  top: calc(40dvh - 35px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(247, 239, 232, 0.8);
}

.container-menu {
  height: calc(60dvh - 48px);
  background-image: url('~@/assets/dayonedayone/images/home-menu-bg.png');
  background-size: contain;
  background-position: right;
  background-color: #f7efe8;
  padding-top: 66px;
}

.sub-container-menu {
  width: 90%;
  height: 20dvh;
  background-color: #fcf7f4;
  margin: 0 auto;
}

.sub-container-menu .sub-menu-label {
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  margin-top: 3px;
}

.home-menu-footer-img {
  bottom: 48px;
  right: 5%;
}

.service-slider, .reward-slider, .promotion-slider {
  width: 100%;
}

.service-slider /deep/ .v-slide-group__wrapper, 
.reward-slider /deep/ .v-slide-group__wrapper, 
.promotion-slider /deep/ .v-slide-group__wrapper {
  touch-action: pan-y !important;
  scroll-behavior: smooth !important;
  scroll-snap-type: x mandatory !important;
}

.service-slider /deep/ .v-slide-group__content {
  justify-content: space-evenly;
}

.service-slider-item-btn-wrapper {
  background-color: var(--v-secondary);
}

.service-slider-item-btn {
  width: calc(25% - 16px) !important;
  min-width: calc(25% - 16px) !important;
}

.footer-divider {
  background-color: rgb(249, 244, 236);
}

@media screen and (min-width: 320px) {
  .service-option {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 360px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .service-option img, .service-option .v-icon {
    width: 18px;
    height: 18px;
  }
}
@media screen and (min-width: 375px) {
  .service-option {
    padding-bottom: 14px;
    padding-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (height: 667px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .service-slider, .promotion-slider {
    padding: 0 16px;
  }
}
@media screen and (min-width: 1024px) {
}
</style>
