import api from './index';

const url = 'merchant';

export default {
  getMerchant() {
    return api.get(`${url}/1`);
  },
  async getMerchantByHostname(hostname) {
    return api.get(`${url}/hostname`, {
      params: { hostname },
    });
  },
  async getMerchantVouchers(clientToken, merchantId, params) {
    return api.get(`${url}/${merchantId}/vouchers`, {
      headers: {
        Authorization: clientToken,
      },
      params
    });
  },
  async getMerchantOffers(merchantId, params) {
    return api.get(`${url}/${merchantId}/offer`, { params });
  },
  async getMerchantPaymentMethods(merchantId, params) {
    return api.get(`${url}/${merchantId}/payment-methods`, { params });
  },
  async getMerchantTableNumbers(merchantId, params) {
    return api.get(`${url}/${merchantId}/table-numbers`, { params });
  },
  async getMerchantBusinessHours(merchantId, params) {
    return api.get(`${url}/${merchantId}/business-hours`, { params });
  },
  getMerchantOperatingHours(merchantId, params) {
    return api.get(`${url}/${merchantId}/operating-hours`, { params });
  },
  getMerchantDeliveryHours(merchantId, params) {
    return api.get(`${url}/${merchantId}/delivery-hours`, { params });
  },
  async getMerchantHolidays(merchantId) {
    return api.get(`${url}/${merchantId}/holidays`);
  },
  async getMerchantReviews(merchantId) {
    return api.get(`${url}/${merchantId}/reviews`);
  },
  async getMerchantPreOreder(merchantId) {
    return api.get(`${url}/${merchantId}/pre-order`);
  },
  async getMerchantNews(merchantId) {
    return api.get(`${url}/${merchantId}/news`);
  },
  async createMerchantReview(merchantId, token, data) {
    return api.post(`${url}/${merchantId}/review`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
};
