<script>
import BottomNav from '@/components/partials/dayonedayone/BottomNav'
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: ['visible'],
  components: {
    BottomNav,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      isLoading: false,
      error: null,
      show: false,
    };
  },
  computed: {
    ...mapState('loyalty', {
      vouchers: (state) => state.vouchers,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
  },
  methods: {
    ...mapActions('loyalty', ['getLoyaltyVouchers']),
    getRewardPhoto(voucher) {
      if (voucher.type !== 'product') {
        return require(`@/assets/images/food-item-default.webp`);
      }

      return voucher.item.photo_s3 ? (`${this.cdnUrl}/${voucher.item.photo_s3}`) : (`${this.legacyCdnUrl}/upload/${voucher.item.photo}`);
    },
  },
  mounted() {
    this.getLoyaltyVouchers({
      merchantId: this.profile.merchant_id,
    });
  },
};
</script>
<template>
  <v-sheet 
    class="voucher-list-sheet mx-auto pt-3 px-4"
    elevation="0"
  >
    <v-sheet 
      ref="voucher-title-sheet"
      class="voucher-title-sheet"
      elevation="0"
    >
      <v-row>
        <v-col align="left">
          <v-img
            contain
            class="ml-3"
            position="left"
            height="60"
            src="@/assets/dayonedayone/images/my-voucher-title.webp"
          ></v-img>
        </v-col>
      </v-row>
      <v-img
        contain
        class="mr-1"
        position="top right"
        height="120"
        src="@/assets/dayonedayone/images/my-voucher-title-img.webp"
      ></v-img>
    </v-sheet>
    <v-sheet 
      ref="voucher-sheet"
      class="voucher-sheet mt-6 p-2"
      color="transparent"
      elevation="0"
    >
      <v-row>
        <v-col align="left">
          <div class="text-body=1 font-weight-bold">Rewards</div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="6"
          :key="`voucher-${voucher.id}`"
          v-for="(voucher, index) in vouchers"
        >
          <v-card
            color="#efe6df"
            class="rounded-xl p-2"
            :class="{
              'ml-2': index % 2 == 1,
              'mr-2': index % 2 == 0,
            }"
            elevation="0"
          >
            <v-img
              class="rounded-xl"
              position="center"
              height="150"
              :src="getRewardPhoto(voucher)"
            ></v-img>
            <v-card-title class="text-caption font-weight-bold p-2">{{ voucher.code }}</v-card-title>
            <v-card-actions class="p-0">
              <v-btn
                class="text-caption text-capitalize font-weight-bold px-0"
                color="tertiary"
                height="24"
                text
              >
                Use
              </v-btn>

              <v-btn
                class="text-caption text-capitalize font-weight-bold px-0"
                color="tertiary"
                height="24"
                text
              >
                Share
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <BottomNav :fixed="true"/>
  </v-sheet>
</template>
<style scoped>
.voucher-list-sheet {
  background-image: url('~@/assets/dayonedayone/images/profile-background.webp');
  background-position: center center;
  background-size: cover;
  background-color: #f7efe8;
  width: 100%;
  height: 100%;
  padding-bottom: 45px;
}

.voucher-list-sheet .voucher-title-sheet {
  background-color: #e1cfc7;
  border-radius: 16px;
  height: 180px;
}

.voucher-list-sheet /deep/ .reward-slider-item.v-card {
  width: 220px;
}

.voucher-list-sheet /deep/ .reward-slider-item.v-card > .v-card__title {
  background-color: #f7efe8;
}

.voucher-list-sheet /deep/ .reward-slider-item.v-card > .v-card__text {
  background-color: #efe6df;
  border-radius: 4px;
}

.voucher-list-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

@media screen and (min-width: 1024px) {
  .voucher-list-sheet /deep/ .reward-slider-item.v-card {
    width: 180px;
  }
}
</style>