<template>
  <v-bottom-sheet v-model="showVoucher">
    <v-card min-height="30vh" max-height="60vh" class="overflow-y-auto p-0">
      <v-card-title class="text-button font-weight-6 justify-center"
        >Available Vouchers</v-card-title
      >
      <v-card height="8" color="listBackground"></v-card>
      <v-card flat v-if="vouchers && vouchers.length == 0">
        <v-list color="#ffffff">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 12px !important"
                class="text-caption font-weight-bold mt-2"
              >
                No voucher at the moment
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card
        flat
        v-for="voucher in vouchers"
        :key="voucher.id"
        color="listBackground"
        class="pb-2 px-3"
      >
        <v-list color="#ffffff">
          <v-list-item class="py-0">
            <v-list-item-action class="mr-2">
              <v-list-item-avatar class="mr-2 bg-light">
                <v-img
                  :src="getProductPhoto(voucher)"
                  v-if="isProductVoucher(voucher)"
                ></v-img>
                <v-icon large color="primary" v-else>mdi-ticket-percent</v-icon>
              </v-list-item-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="
                  text-body-2 text-wrap text-uppercase
                  primary--text
                  font-weight-bold
                "
              >
                {{ voucher.code }}
                <v-chip
                  small
                  class="text-caption text-capitalize mr-1 mb-1 limited-offer"
                  v-if="voucher.visibility"
                  >Private</v-chip
                >
								<v-chip
									small
									class="text-caption text-lowercase mr-1 mb-1 limited-quantity"
									v-if="voucher.claimed > 1"
									>x{{ voucher.claimed }}</v-chip
								>
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-1">
                <div class="d-flex flex-column">
                  <small
                    class="font-weight-6 green--text text--darken-1 my-1"
                    style="font-size: 75%"
                  >
                    {{
                      voucher.type === 'percentage'
                        ? voucher.amount + '%'
                        : $n(voucher.amount, 'currency', 'en-MY')
                    }}
                    off
                  </small>
                  <small
                    class="font-weight-5 green--text text--darken-1"
                    style="font-size: 75%"
                  >
                    Min. purchase
                    <span class="font-weight-6">{{
                      $n(voucher.min_purchase, 'currency', 'en-MY').split(
                        '.'
                      )[0]
                    }}</span>
                  </small>
                  <small
                    class="font-weight-5 mt-4"
                    style="font-size: 65%"
                    v-if="isProductVoucher(voucher)"
                  >
                    * For <span class="font-weight-bold text-wrap">{{ voucher.item.name }}</span> only
                  </small>
                  <small class="mt-3" style="font-size: 65%" v-if="canUse(voucher.startAt)">
                    ** Valid until
                    <b>{{ formatDate(voucher.expiration) }}</b></small
                  >
                  <small class="mt-3" style="font-size: 65%" v-else>
                    <v-icon small dense class="mb-1">mdi-timer-sand</v-icon> Use from: 
                    <b>{{ formatDate(voucher.startAt) }}</b></small
                  >
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap mt-2">
                <v-chip
                  small
                  class="text-caption mr-1 my-1 limited-offer"
                  v-if="voucher.delivery"
                  >Delivery</v-chip
                >
                <v-chip
                  small
                  class="text-caption mr-1 my-1 limited-offer"
                  v-if="voucher.pickup"
                  >Pickup</v-chip
                >
                <v-chip
                  small
                  class="text-caption mr-1 my-1 limited-offer"
                  v-if="voucher.dinein"
                  >Dine In</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="isLoggedIn">
              <v-list-item-action-text
                class="text-overline font-weight-bold"
                v-if="voucher.fully_redeemed"
              >
                <div class="stamp px-2">
                  Fully Redeemed
                </div>
              </v-list-item-action-text>
              <v-list-item-action-text
                class="text-overline font-weight-bold"
                v-else-if="voucher.claimed > 0"
              >
                <v-btn x-small outlined ripple color="disabled" v-if="canUse(voucher.startAt)">
                  Claimed
                </v-btn>
                <v-btn x-small outlined ripple color="disabled" v-else>
                  Use Later
                </v-btn>
              </v-list-item-action-text>
              <v-list-item-action-text
                class="text-overline font-weight-bold"
                v-else
              >
                <v-btn
                  x-small
                  ripple
                  color="primary"
                  class="text-overline font-weight-6 px-4 py-3"
                  @click.stop="claim(voucher)"
                >
                  Claim
                </v-btn>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
			<v-overlay :value="isLoading">
				<v-progress-circular indeterminate size="50"></v-progress-circular>
			</v-overlay>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'VoucherBottomSheet',
  props: ['visible'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
			isLoading: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
      vouchers: (state) => state.vouchers,
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    showVoucher: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-voucher', false);
        }
      },
    },
  },
  methods: {
    ...mapActions('customer', ['claimVoucher']),
		...mapActions('merchant', ['getMerchantVouchers']),
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY');
    },
    canUse(startAt) {
      return !startAt || !dayjs().isBefore(dayjs(startAt), 'day');
    },
    isProductVoucher(voucher) {
      return voucher.type === 'product';
    },
    getProductPhoto(voucher) {
      return voucher?.item?.photo_s3
        ? `${this.cdnUrl}/${voucher?.item?.photo_s3}`
        : `${this.legacyCdnUrl}/upload/${voucher?.item?.photo}`;
    },
		async claim(voucher) {
			try {
				this.isLoading = true;

				this.$gtag.event('claim-voucher', {
					event_category: 'engagement',
					event_label: 'Claim Voucher',
					event_value: voucher.id,
				});

				await this.claimVoucher({
					voucherId: voucher.id,
					merchantId: this.profile.merchant_id,
				});

				await this.getMerchantVouchers({
					merchantId: this.profile.merchant_id,
				});

				this.isLoading = false;
			} catch(e) {
				this.isLoading = false;
				alert(e.message);
			}
		},
  },
  async mounted() {
    await this.getMerchantVouchers({
      merchantId: this.profile.merchant_id,
    });
  },
};
</script>
<style scoped>
.limited-offer {
  font-size: 8px !important;
  font-weight: 600;
  height: 18px;
  border-radius: 8px;
  color: #039be5 !important;
  background-color: rgba(3, 155, 229, 0.1) !important;
}
.limited-quantity {
  font-size: 10px !important;
  font-weight: bold;
  height: 20px;
  border-radius: 8px;
  color: #ff6347 !important;
  background-color: rgba(255, 99, 71, 0.1) !important;
}
.v-list-item__action-text {
  color: #ff6347 !important;
}
.stamp {
	position: absolute;
	top: 28%;
	right: 2%;
  transform: rotate(-15deg);
	color: #ff6347;
	font-size: 0.4rem;
	font-weight: 700;
	border: 0.25rem solid #ff6347;
	text-transform: uppercase;
	border-radius: 1rem;
	mask-image: url('../../assets/images/grunge.webp');
	-webkit-mask-image: url('../../assets/images/grunge.webp');
  mix-blend-mode: multiply;
}
</style>